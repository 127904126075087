.timer {
  font-size: 1rem;
}

.colored-red {
  border-color: hsla(53, 61%, 20%, 0.992);
  font-size: 1.6em;
}

.colored-green {
  border-color: hsl(318, 66%, 20%);
  font-size: 1.6em;
}

.show-true {
  opacity: 1;
}

.first-box {
  animation: shakeh 0.5s ease-out infinite alternate-reverse;
}

@keyframes shakeh {
  0% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(5%);
  }
}

.second-box {
  animation: shakev 1s ease-in-out infinite alternate-reverse;
}

@keyframes shakev {
  0% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(5%);
  }
}

.fourth-box {
  animation: shakeh 0.1s ease-in infinite alternate-reverse;
}
