body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blinker 0.6s linear 7;
}

.blur {
  color: transparent;
  text-shadow: 0 0 1px hsl(25, 0%, 90%, 1);
}

.h {
  border-color: white;
  border-width: 1px;
}

@font-face {
  font-family: "Limelight";
  src: local("Limelight"), url(./fonts/Limelight.ttf) format("truetype");
}

@font-face {
  font-family: "LimelightHeading";
  font-weight: 700;
  src: local("Limelight"), url(./fonts/Limelight.ttf) format("truetype");
}

@font-face {
  font-family: "BroadbayNormal";
  src: local("BroadbayNormal"),
    url(./fonts/BroadbayNormal.ttf) format("truetype");
}

@font-face {
  font-family: "BroadbayNormallHeading";
  font-weight: 700;
  src: local("BroadbayNormal"),
    url(./fonts/BroadbayNormal.ttf) format("truetype");
}

@font-face {
  font-family: "Cinzel";
  src: local("Cinzel-Regular"),
    url(./fonts/Cinzel-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "CinzelHeading";
  font-weight: 700;
  src: local("Cinzel-Bold"), url(./fonts/Cinzel-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Slabo";
  src: local("Slabo"), url(./fonts/Slabo.ttf) format("truetype");
}

@font-face {
  font-family: "SlaboHeading";
  font-weight: 700;
  src: local("Slabo"), url(./fonts/Slabo.ttf) format("truetype");
}

@font-face {
  font-family: "TerminalDay";
  src: local("TerminalDay"), url(./fonts/TerminalDay.ttf) format("truetype");
}

@font-face {
  font-family: "TerminalDayHeading";
  font-weight: 700;
  src: local("TerminalDay"), url(./fonts/TerminalDay.ttf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis-Medium"),
    url(./fonts/Metropolis-Medium.otf) format("truetype");
}

@font-face {
  font-family: "MetropolisHeading";
  font-weight: 700;
  src: local("Metropolis-Bold"),
    url(./fonts/Metropolis-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Avalon";
  src: local("AvalonRegular"), url(./fonts/AvalonRegular.ttf) format("truetype");
}

@font-face {
  font-family: "AvalonHeading";
  font-weight: 700;
  src: local("AvalonRegular"), url(./fonts/AvalonRegular.ttf) format("truetype");
}

@font-face {
  font-family: "NiteClub";
  src: local("NITECLUB"), url(./fonts/NITECLUB.TTF) format("truetype");
}

@font-face {
  font-family: "NiteClubHeading";
  font-weight: 700;
  src: local("NITECLUB"), url(./fonts/NITECLUB.TTF) format("truetype");
}

@font-face {
  font-family: "Square";
  src: local("Square"), url(./fonts/Square.ttf) format("truetype");
}

@font-face {
  font-family: "SquareHeading";
  font-weight: 700;
  src: local("Square"), url(./fonts/Square.ttf) format("truetype");
}

@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"), url(./fonts/Oxanium.ttf) format("truetype");
}

@font-face {
  font-family: "OxaniumHeading";
  font-weight: 200;
  src: local("Oxanium"), url(./fonts/Oxanium.ttf) format("truetype");
}
