.home-box-mobile {
  position: absolute;
  margin: 0 auto;
  border-radius: 1px;
  opacity: 0;
  border-width: 1px;
  border-color: rgb(110, 110, 110);
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.box-mobile {
  min-width: 100px;
}

.colored-red {
  border-color: hsla(53, 61%, 20%, 0.992);
  font-size: 1.4em;
}

.colored-green {
  border-color: hsl(318, 66%, 20%);
  font-size: 1.4em;
}

.show-true-mobile {
  opacity: 1;
}
