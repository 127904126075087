.about-box {
  margin: 0 auto;
  width: 420px;
  height: 420px;
  border-radius: 1px;
  border-width: 1px;
  border-color: rgb(110, 110, 110);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.about-box-mobile {
  width: 350px;
  height: 350px;
}

.faq-box {
  padding: 10px;
  margin: 0 auto;
  border-radius: 1px;
  border-width: 1px;
  border-color: rgb(110, 110, 110);
  align-items: center;
  text-align: center;
}

.faq-box p {
  margin-bottom: 20px;
}
